<template>
<div>
  <Loading :isLoading="isLoading" />
  <div>
    <p style="padding:10px 10px 0px 10px;font-size:20px;color:#810042;font-weight:700;font-family:Lato;">Solicitudes en Asignadas</p>
  </div>
  
  <div class="py-2">
    <TableComponent :key="render" :columns="columns" :rows="rows" :rangeInput="true" :exportInput="true"/>
  </div>
</div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from "vue";
import Loading from '@/components/Loading/VueLoading.vue';

import TableComponent from "@/components/Forms/Tables/TableComponent";
import {getTokenDecoden} from "@/helpers/tokenauth";
import Affiliated from '@/classes/Affiliated.js';
import { useRouter } from 'vue-router';

export default {
  name: 'SolicitudesEnProceso',
  setup(){
    const isLoading = ref(true);
    const apiToken = ref("");
    const personId = ref("");
    const requests = ref([]);
    const requestType = ref("");
    const associates = ref([]);
    const userId = ref(0);
    const assignmentType = ref("Reasignar");
    const affiliatedObject = ref(null);
    const columns = ref([]);
    const rows = ref([]);
    const router = useRouter();

    let app = getCurrentInstance();
    let render = ref(false);

    onMounted(async () => {
      app = app.appContext.config.globalProperties;
      affiliatedObject.value = new Affiliated();
      loadInfo();
      await getAssociates();
      await getRequests();
      isLoading.value = false;
    });

    const assign = async(idRequest, idAssociated) => {
      var swal = require('sweetalert2')
      isLoading.value = true;

      var status = "en_revision";
      await affiliatedObject.value.setAnalysts(apiToken.value, idAssociated, personId.value, userId.value, idRequest).then((response)=>{
        getRequests(status).then((response) => {
          swal.fire({
            title: "Aviso",
            text: "La solicitud fue asignada a un asociado correctamente",
            icon: "success",
            confirmButtonColor: '#FEB72B',
          });
        });
      });
    }

    const getRequests = async() => {
      render.value = false;
      await affiliatedObject.value.getNewRequests(apiToken.value, 'en_revision').then((response) => {
        //let fields = getFinalArrayList(response.data.response.admission_request);

        const data = response;
        const dataFind = [];
        data.data.response.admission_request.find(admissionRequest => {
          if(admissionRequest.admission_analyst_id == userId.value)
            dataFind.push(admissionRequest);
        });

        let finalArray = getFinalArrayList(dataFind);
        requests.value = finalArray.reverse();
        
        //requestsCount = dataFind.length;
        isLoading.value = false;
  
        getColumns();       
        rows.value = requests.value;
        render.value = true;
        isLoading.value = false;
      });
    }

    const getAssociates = async () =>{
      affiliatedObject.value.getAllAnalysts(apiToken.value).then(response => {
        associates.value = response.data.response.analysts;
      });
    }

    const getColumns = () => {
      columns.value = [
        { headerName: "RFC", field: "rfc"},
        { headerName: "Nombre", field: "name" },
        { headerName: "Telefono", field: "phone" },
        { headerName: "Correo", field: "email" },
        { headerName: "Monto a ahorrar", field: "savingAmount" },
        { headerName: "Puesto/Ocupación", field: "occupation" },
        { headerName: "Fecha de registro", field: "date" },
        { headerName: "Usuario asignado", field: "asigned_name" },
        { headerName: "Ver información", field: "view", filter: false, cellRenderer: params => {
          const route = {
            path: "/supervisor/cuenta/revision-solicitudes-ingreso/"+params.value
          };

          const link = document.createElement("a");
          link.href = router.resolve(route).href;
          link.innerText = "Ver";
          link.className += "bg-cherryColor text-white focus:bg-blue-700 px-10 py-1.5 mx-auto rounded-md cursor-pointer text-base";
          link.addEventListener("click", e => {
            e.preventDefault();
            router.push(route);
          });
          return link;
        }},
        { headerName: "Analistas", field: "analysts", filter: false, cellRenderer: params => {
          const select = document.createElement("select");
          select.setAttribute('id', `select_${params.value.admission_request_id}`)
          select.setAttribute('name', `select_${params.value.admission_request_id}`)
          select.className+="w-full bg-gray-100   px-5 py-0.5 mx-auto rounded-md cursor-pointer border-2 ";

          //Create and append the options
          for (var i = 0; i < associates.value.length; i++) {
              var opt = document.createElement("option");
              opt.value = associates.value[i].id;
              opt.text = app.$filters.formatCamelize(`${associates.value[i].name} ${associates.value[i].middle_name} ${associates.value[i].paternal_name}`);
              
              if(associates.value[i].id == params.value.admission_analyst_id){
                opt.setAttribute('value', params.value.admission_analyst_id);
                opt.setAttribute('selected', 'selected');
              }
              select.add(opt, 1);
          }

          return select;
        }},
        { headerName: "Acciones", field: "analysts", filter: false, cellRenderer: params => {
          const admissionRequestId = params.data.action.admission_request_id;
          const link = document.createElement("label");
          link.innerText = "Asignar";
          link.setAttribute('id', `link_${params.value.admission_request_id}`)
          link.setAttribute('name', `link_${params.value.admission_request_id}`)
          link.className += "bg-cherryColor text-white focus:bg-blue-700	px-9 py-0.5 mx-auto rounded-lg cursor-pointer";
          link.addEventListener("click", e => {
            e.preventDefault();

            let analystId = document.getElementById(`select_${params.data.action.admission_request_id}`).value;
            assign(admissionRequestId, analystId);
          });
          return link;
        }},
      ];
    }

    const viewRequest = (value) => {
      router.push({path: value})
    }

    const loadInfo = () => {
      var auth = getTokenDecoden();
      apiToken.value = sessionStorage.getItem("login");
      personId.value  = auth.obj.personnel_info['id'];
      userId.value  = auth['id'];
    }
    
    const getFinalArrayList = (array) => {
      let finalArray = [];
      
      for (let i = 0; i < array.length; i++) {
        let contact_info = convertListToArray(array[i].admission_information.contact_info_attributes);

        var occupation = array[i].admission_information.work_info_attributes.occupation;
        var laboralStatus = array[i].admission_information.work_info_attributes.laboral_status;
        occupation = app.$filters.formatCamelize(laboralStatus.toLowerCase() == "jubilado" ? "jubilado" : occupation);

        finalArray.push({
          rfc: array[i].admission_information.rfc,
          name: app.$filters.formatCamelize(`${array[i].admission_information.name} ${array[i].admission_information.middle_name} ${array[i].admission_information.paternal_name} ${array[i].admission_information.maternal_name}`),
          phone: contact_info.filter(({contact_type}) => contact_type === 'celular').length > 0 ? contact_info.filter(({contact_type}) => contact_type === 'celular')[0].value : "1111111111",
          email: contact_info.filter(({contact_type}) => contact_type === 'correo').length > 0 ? contact_info.filter(({contact_type}) => contact_type === 'correo')[0].value : "correo@gmail.com",
          savingAmount: app.$filters.formatCurrency(array[i].admission_information.saving_registration_attributes.amount == "" ? "0" : array[i].admission_information.saving_registration_attributes.amount),
          occupation: occupation,
          date : app.$filters.formatDate(array[i].created_at),
          asigned_name: array[i].status == "en_revision" ? getAsignedUser(array[i].admission_analyst_id) : "",
          view: array[i].id,
          analysts: {
            admission_analyst_id: array[i].admission_analyst_id,
            admission_request_id:array[i].id
          },
          action: {
            admission_analyst_id: array[i].admission_analyst_id,
            admission_request_id:array[i].id
          }
        })
      }
      return finalArray;
    }

    const convertListToArray = (list) => {
      let index = [];
      let array = [];
      for (var i in list) {
        index.push(i);
      }

      for (let i = 0; i < index.length; i++) {
        array.push(list[index[i]]);
      }

      return array;
    }

    const getAsignedUser = (value) => {
      var asociate = associates.value.filter((associate) => associate.id == value)
      if(asociate.length > 0){
        return `${asociate[0].name.toUpperCase()} ${asociate[0].paternal_name.toUpperCase()}`
      }else{
        return ""
      }
    }


    return {
      isLoading,
      apiToken,
      personId,
      requests,
      requestType,
      associates,
      userId,
      assignmentType,
      assign,
      columns,
      rows,
      render,
      viewRequest
    }
  },
  components: {
    Loading,
    TableComponent
  },
}

</script>